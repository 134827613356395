.editor {
  background-color: $white;
  border: 2px solid;
  border-color: $lineGrey;
  border-radius: $radius;
  overflow: hidden;
  padding: calcRem(40px);

  & + & {
    margin-top: calcRem(10px);
  }
}