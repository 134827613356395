body {
  color: $darkGrey;
  font-family: $font-text-reg;
  font-size: 100%;
  line-height: 1.45;
}

%hbase {
  font-family: $font-disp-bold;
  line-height: 1;
  margin-bottom: calcRem(16px);
}

// h1 through h8
@for $i from 1 through 7 {
  
  h#{$i}, .h#{$i} {
    @extend %hbase;

    //h1, .h1
    @if($i == 1) {
      font-size: calcRem(48px);
    }

    //h2, .h2
    @if($i == 2) {
      font-size: calcRem(32px);
    }

    //h3, .h3
    @if($i == 3) {
      font-size: calcRem(24px);
    }

    //h4, .h4
    @if($i == 4) {
        font-size: calcRem(21px);
    }

    //h5, .h5
    @if($i == 5) {
        font-size: calcRem(18px);
    }

    //h6, .h6
    @if($i == 6) {
        font-size: calcRem(16px);
    }
  }
}

p,
ul,
ol,
small,
dl,
dt,
dd {
  margin-bottom: calcRem(16px);
}

strong {
  font-family: $font-text-bold;
}

small {
  font-size: 72%;
}

code {
  background-color: $darkGrey;
  color: $blue;
  padding: 4px 4px 6px;
}