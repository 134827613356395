/*
  OOCSS Approach: buttons can be separated intro 3 categories but will ALWAYS derive from the base %btn.
    - Shape
    - Color
    - Size
    - Icons
*/

%btn {
  background-clip: border-box;
  background-color: $blue;
  border-radius: 50px;
  border: 1px solid;
  border-color: $blue;
  color: $white;
  cursor: pointer;
  display: inline-block;
  padding: calcRem(9px) calcRem(20.8px);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  &:focus,
  &:active {
    outline:0;
  }
  & + & {
    margin-left: calcRem(8px);
  }
}

//Standard
.btn {
  @extend %btn;
  .custom-checkbox-outer + &, .field-wrap + & {
    margin-top: calcRem(16px);
  }
}

//~~~~ Shape
.btn-square {
  border-radius: $radius;
}

//~~~~ Color
.btn-dark {
  background-color: $darkGrey;
  border-color: $darkGrey;
}

.btn-silent {
  background-color: transparent;
  border-color: transparent;
  color: $darkGrey;
  padding-left: calcRem(9px);
  padding-right: calcRem(9px);

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

//~~~~ Color
.btn-grey {
  background-color: $midGrey;
  border-color: $midGrey;
}

//~~~~ Size
.btn-full {
  display: block;
  width: 100%;

  & + & {
    margin-top: calcRem(16px);
  }
}

.btn-small {
  font-size: calcRem(12px);
  padding: calcRem(6px) calcRem(10px);
}

.btn-smaller {
  font-size: calcRem(12px);
  padding: calcRem(4px) calcRem(8px);
  margin-top: -0.5em;
}

.btn-large {
  font-family: $font-disp-bold;
  font-size: calcRem(18px);
  padding: calcRem(18px) calcRem(50px);
}

//~~~~ Button with Icons
.btn-with-icon {
  padding-left: calcRem(20.8px * 2);
  position: relative;

  .icon {
    @include centerer(false,true);
    left: calcRem(14px);
    height: calcRem(16px);

    svg {
      fill: $white;
      height: inherit;
    }
  }
}

//~~~~ Button with ONLY Icon
.btn-icon {
  height: 40px;
  width: 40px;
  padding: calcRem(9px);
  position: relative;

  .icon {
    height: calcRem(20px);
  }
  .icon svg {
    @include centerer;
    display: block;
    fill: white;
    max-height: 20px;
    max-width: 20px;
  }
}

//~~~~ A group of buttons
.btn-group  {
  %btn {
    margin-bottom: 8px;
    margin-right: 4px;
  }
}

.btn-col {
  vertical-align: text-bottom;
  text-align: right;
  margin-top: 25px;
}



.btn--change-thumbnail {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.875rem;

  & svg {
    margin-left: 0.5rem;
  }
}



// %btn {
//   background-color: #4fc3f7;
//   color: #ffffff;
//   cursor: pointer;
//   padding: 0.5em 1.3em;

//   &:not(:first-child) {
//     margin-left: 1em;
//   }
//   &:focus {
//     outline:0;
//   }
// }

// .btn {
//   @extend %btn;
//   border-radius: 3em;
// }

// .btn-square {
//   @extend %btn;
//   border-radius: 0.2em;
// }

// .btn-new-item {
//   border-radius: 0.2em;
//   border: 1px solid $blue;
//   padding: 0.5em 1.5em;
//   padding-right: 2em;
//   font-weight: 100;
//   position: relative;
//   &:after {
//     content: '+';
//     border: 2px solid $blue;
//     border-radius: 50%;
//     min-height: 0.9em;
//     min-width: 0.9em;
//     line-height: 0.9;
//     color: $blue;
//     font-weight: bold;
//     font-size: 0.9em;
//     position: absolute;
//     right: 0.5em;
//     text-align: center;
//   }
// }

// %icn-btn {
//   background: #4a4a4a;
//   color: $white;
//   cursor: pointer;
//   float: right;
//   padding: 0.5em;
// }

// .plus-btn {
//   @extend %icn-btn;

//   &:after {
//     content:'+';
//   }
// }

// .x-btn {
//   @extend %icn-btn;

//   &:after {
//     content:'x';
//   }
// }
