//the input
.selectize-dropdown,
.selectize-input,
.selectize-input input {
  font-size: calcRem(16px);

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $darkGrey;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $darkGrey;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $darkGrey;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $darkGrey;
  }
}

.selectize-input,
.selectize-control.single .selectize-input {
  background-color: $white;
  background-image: none;
  background-repeat: no-repeat;
  background-position: calc(100% - 13px) center;
  border: 2px solid;
  border-color: $lineGrey;
  border-bottom-color: black;
  border-radius: 0;
  box-shadow: none;
  color: $darkGrey;
  line-height: 1.45;

  padding-bottom: calcRem(6.5px);
  padding-top: calcRem(6.5px);
  padding-left: calcRem(16px);
  padding-right: calcRem(16px);

  //when the input is active
  &.input-active,
  &.not-full {
    border-color: $lineGrey;
    box-shadow: none;
    background-image: url('../images/icon-caret-up.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 13px) center;
  }

  //when the dropdown next to input is active
  &.dropdown-active {
    border-radius: 0;
  }

  .multi & {
    background-image: none;
  }
}

//the dropdown icon
.selectize-control.single .selectize-input:after {
  // border: none;
  display: none;
}

//the dropdown with results
.selectize-dropdown,
.selectize-control.single .selectize-dropdown {
  border: 2px solid;
  // border-top: none;
  border-color: $lineGrey;
  border-radius: 0;
  box-shadow: none;
}

//the items within the dropdown
.option[data-selectable] {
  font-size: calcRem(16px);
  line-height: 1.45;
  overflow: hidden;

  &:hover,
  &:first-of-type {
    background-color: $white;
    color: $black;
  }

  &:first-of-type {
    border-top-color: transparent;
  }

  //when dropdown is active
  //we find any items withint dropdown
  .dropdown-active ~ .selectize-dropdown & {
    background-color: $white;
    color: $black;
  }
}

//the mutli selector item container
.selectize-control.multi .selectize-input.has-items {
  padding: calcRem(9px) calcRem(13px);
}

//the multi selector items
//and active state
.selectize-control.multi .selectize-input [data-value],
.selectize-control.multi .selectize-input [data-value].active {
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
  color: $darkGrey;
  margin: 0;
  padding-bottom: 0;
  padding-left: calcRem(14px);
  padding-right: 0 !important; //default uses important too
  padding-top: 0;
  text-shadow: none;

  &:after {
    content: '';
    margin-right: calcRem(16px);
  }
}

//when multi selector items are next to eachother
// .selectize-control.multi .selectize-input [data-value] + [data-value] {
//   margin-left: calcRem(18px);
// }

.selectize-control.plugin-remove_button [data-value] .remove {
  border: none;
  font-size: 100%;
  left: 0;
  // line-height: 1.45;
  padding: 0;
  right: auto;
  width: auto;

  &:hover {
    background-color: transparent;
  }
}


.selectize-dropdown-content {
  max-height: calcRem(220px);
}


//crazy border shenanigans
.selectize-dropdown-content .option {
  border: 1px solid;
  border-color: white;
  border-top-color: $lightGrey;
  border-left: 4px solid;
  border-left-color: transparent;

  padding-bottom: (9px);
  padding-top: (9px);
  padding-right: (13px);
  padding-left: (9px);

  &:hover {
    border-left-color: $black;
  }

  &.is-selected,
  &.is-selected.active,
  &.is-selected.selected.active {
    border-left-color: $red;
  }
}
