.tickets {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.ticket {
  background-color: $white;
  border: 1px solid;
  border-color: $lineGrey;
  border-radius: $radius;
  color: inherit;
  position: relative;
  text-decoration: none;
}

.ticket {
  //Settings
  $count: 5;
  $gutter: 20px;
  $ratio: percentage(1/$count);
  
  flex-basis: calc(#{$ratio} - #{$gutter} + #{$gutter} / #{$count});
  margin-bottom: $gutter;
  margin-right: $gutter;

  &:nth-child(#{$count}n) {
    margin-right: 0;
  }
}

.ticket__img {
  height: calcRem(160px);
  overflow: hidden;
  position: relative;

  img {
    @include centerer;
    display: block;
    max-width: 180%;
  }
}

.ticket__body {
  padding: calcRem(16px) calcRem(16px);

  display: flex;
  flex-direction: column;

  .ticket__img + & {
    border-top: 1px solid;
    border-color: $lineGrey;
  }
}

.ticket__controls {}

.ticket__title,
.ticket__info {
  margin-bottom: 0;
}

.ticket__title {
  font-size: calcRem(14px);
  font-family: $font-text-bold;
}

.ticket__info {
  font-size: calcRem(12px);
}

.ticket__controls {
  align-items: center;
  background-color: rgba($darkGrey, .85);
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 100ms ease;
  width: 100%;

  .ticket:hover & {
    opacity: 1;
    transition: opacity 250ms ease;
  }
}

.ticket__link {
  align-items: center;
  color: $white;
  border: 2px solid;
  border-color: transparent;
  border-radius: 50px;
  display: flex;
  font-family: $font-text-bold;
  font-size: calcRem(14px);
  justify-content: center;
  max-width: calcRem(176px);
  padding: calcRem(4px) calcRem(8px);
  text-decoration: none;
  transition: border-color 100ms ease-in;
  width: 90%;

  & + & {
    margin-top: calcRem(8px);
  }

  &:focus,
  &:hover {
    border-color: $white;
    transition: border-color 250ms ease-out;
  }

  .icon {
    margin-right: calcRem(4px);
    height: calcRem(14px);
  }

  svg {
    fill: $white;
    height: inherit;
  }
}