//this component lives on every page
//think of it as the page intro

.opening {
  background-color: $ultraGrey;
  border-bottom: 1px solid;
  border-color: $lineGrey;
  padding: calcRem(40px);
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    background-color: $white;
    font-family: $font-disp-reg;
    padding-bottom: calcRem(11px);
    padding-top: calcRem(11px);
  }
}

.opening__title,
.opening__subtitle {
  margin-bottom: 0;

  span {
    font-family: $font-disp-reg;
  }
}

.opening__title {
  background-color: transparent;
  border: none;
  font-family: $font-disp-bold;
  font-size: calcRem(32px);
  line-height: 1;
  padding: 0;
  width: 100%;
}

.opening__subtitle {
  font-family: $font-text-bold;
  
  span {
    color: $midGrey;
    font-size: smaller;
    margin-left: calcRem(4px);
  }
}

.opening__body {
  width: 50%;

  .opening + .opening & {
    width: 75%;
    overflow-x: scroll;
  }

  .opening + .opening &:only-child {
    align-items: center;
    display: flex;
    width: 100%;
  }
}

.opening__innerbody {
  align-items: center;
  display: flex;
  width: 50%;

  .label {
    margin-right: calcRem(20px);
  }

  .custom-select-wrap + .custom-select-wrap {
    margin-left: calcRem(8px);
  }

  .field {
    border: 1px solid;
    border-color: $lineGrey;
  }
}

.opening__controls {
  text-align: right;
  width: 50%;

  .opening + .opening & {
    width: 25%;
  }
}

.opening__list {
  margin-bottom: 0;
  padding-left: 0;
}

.opening__item {
  text-align: left;
  & + & {
    margin-left: calcRem(8px);
  }
}

.opening__label, 
.opening__action {
  display: inline-block;
  vertical-align: middle;
}

.opening__label {
  font-family: $font-text-bold;
  font-size: calcRem(14px);
  margin-bottom: 0;

  & + .opening__action {
    margin-left: calcRem(10px);
  }
}

.opening__action {
  background-color: $lightGrey;
  border: 1px solid;
  border-color: $lineGrey;
  padding: 10px 14px;

  & + & {
    margin-left: -1px;
  }

  svg {
    display: block;
    fill: $midGrey;
    max-height: 18px;
  }
  &:hover svg {
    fill: $darkGrey;
  }
}