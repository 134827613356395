//Primary colors
$black: #000000;
$blue: #4fc3f7;
$cool: #b0bec5;
$darkGrey: #343434;
$green: #81c784;
$inactive: #bfbdbd;
$ultraGrey: #f9f9f9;
$lightGrey: #f1f1f1;
$lineGrey: #d8d8d8;
$midGrey: #6e6e6e;
$red: #f44336;
$white: #ffffff;

$unit: 16px;

$radius: 2px;

$gridSize: 64px;
$gridNumX: 100;
$gridNumY: 100;
$gridNum: 100;
$edgeSize: 8px;
$maxEdgesPerSide: 5;

//Base font stack
$font-base: 'Helvetica Neue', Helvetica, sans-serif;

//Fonts Text (Only used if 14px or smaller)
$font-text-reg: 'NeueHaasGroteskText W01', $font-base;
$font-text-italic: 'NHaasGroteskTXW01-56It';
$font-text-bold: 'NHaasGroteskTXW01-75Bd';
$font-text-bold-italic: 'NHaasGroteskTXW01-76BdI';

//Fonts Text (Only used if larger than 14px)
$font-disp-reg: 'NeueHaasGroteskDisp W01', $font-base;
$font-disp-italic: 'NHaasGroteskDSW01-56It';
$font-disp-bold: 'NHaasGroteskDSW01-75Bd';
$font-disp-bold-italic: 'NHaasGroteskDSW01-76BdI';