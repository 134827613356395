.file-preview {
  max-width: 20rem;
  margin-bottom: 1rem;
}

.file-image {
  width: 100%;
  margin-bottom: 0.75rem;

  img {
    display: block;
    width: 100%;
  }
}

.file-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.file-info__name {
  font-size: 0.675rem;
  width: 75%;
  display: block;
  opacity: 0.75;
}

.file-info__download {
  opacity: 0.5;
  transition: all 200ms;

  &:hover {
    opacity: 1;
  }
}