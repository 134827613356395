.inline-list {
  li {
    display: inline-block;
  }
}

.silent-list {
  list-style-type: none;
}

.link-list {
  li + li {
    margin-left: calcRem(4px);
  }
}






















// .styleguide-begin {
//   border-bottom: 10px solid $blue;
//   margin-bottom: 2em;
// }

// .style-header {
//   border-left: 10px solid $blue;
//   margin-bottom: 1.125rem;
//   padding: 1.5rem;

//   .h1, .h2 {
//     margin-bottom: 0;
//   }
// }

// .styleguide-section {
//   margin-bottom: 2em;
// }

// .grey-bg {
//   background: $darkGrey;
//   padding: 1em;
//   color: $white;
//   margin-top: 1em;
// }