.tabs {
  font-size: 0;
}

.tab {}

.tab-mark {
  background-color: $lightGrey;
  border: 1px solid;
  border-color: $lineGrey;
  cursor: pointer;
  display: inline-block;
  font-family: $font-text-reg;
  font-size: calcRem(16px);
  padding: calcRem(8px) calcRem(20px);
  vertical-align: bottom;
  margin-bottom: calcRem(-1px);
  margin-right: calcRem(-1px);
  margin-top: calcRem(-1px);

  span {
    display: block;
  }

  [type="radio"]:checked + &,
  &.active {
    background-color: $white;
    border-bottom-color: $blue;
  }

  &.disabled {
    color: $inactive;
    pointer-events: none;
  }
}

.tab-title {
  font-family: $font-text-bold;
}

.tab-info {
  color: $midGrey;
  font-size: calcRem(11px);
}

.tab-content {
  background-color: $white;
  border: 1px solid;
  border-color: $lineGrey;
  clear: both;
  font-size: calcRem(16px);
  padding: calcRem(20px);
}

.tab-body {
  display: none;

  &.active {
    display: block;
  }
}
