.message-bar {
  color: #ffffff;
  bottom: 0;
  left: 0;
  padding: 1em;
  position: fixed;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.message-bar-error {
  background-color: #c82121;
}

.message-bar-success {
  background-color: #66bb6a;
}

.toast-container {

}
