button {
  cursor: pointer;
  outline: none;
  text-align: left;
}

input {
  font-family: $font-text-reg;
  font-size: inherit;
}

textarea {
  resize: none;
}