input,
textarea,
select {
  font-family: $font-text-reg;
  font-size: calcRem(16px);
  line-height: 1.45;
  outline: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  &.has-error {
    border-color: $red;
  }
}

//removes the yellow autofill bg color
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.form {
  margin-bottom: calcRem(16px);

  //form layout
  & > div ~ div,
  & > fieldset ~ fieldset {
    margin-top: calcRem(16px);
  }

  //If there are form controls
  & > div ~ .form-controls,
  & > fieldset ~ .form-controls {
    margin-top: calcRem(32px);
  }

  & + & {
    margin-top: calcRem(16px);
  }
}

fieldset + .h5 {
  margin-top: calcRem(8px);
}

.label {
  display:inline-block;
  font-family: $font-text-bold;
  font-size: calcRem(11px);
  margin-bottom: calcRem(4px);

  .inline-field-wrap + & {
    margin-bottom: 0;
  }

  &--medium {
    font-size: 0.75rem;
  }

  &--large {
    font-size: 0.875rem;
  }
}

.label-paragraph {
  font-size: 100%;
  margin-bottom: calcRem(16px);
}

.block-label {
  display: block;
}

.inline-label {
  margin-bottom: 0;
}

.field-wrap {
  position: relative;
}

.inline-field-wrap {
  @extend .field-wrap;
  display: inline-block;
  vertical-align: middle;

  .label + & {
    margin-left: calcRem(8px);
  }
  & + .label {
    margin-left: calcRem(8px);
  }
}

.field {
  background-color: $white;
  border-color: $lineGrey;
  border: 2px solid;
  border-color: $lineGrey;
  border-radius: $radius;
  color: $darkGrey;
  width: 100%;

  padding-bottom: calcRem(6.5px);
  padding-top: calcRem(6.5px);
  padding-left: calcRem(16px);
  padding-right: calcRem(16px);

  &:focus,
  &:active {
    border: 2px solid;
    border-color: $blue;
  }

  &[disabled] {
    background-color: $white;
    cursor: default; 
    background-color: $white;
  }
}

mapping-table {
  .field {
    background-color: $lightGrey;
  }
}

.field-pill {
  @extend .field;
  border-radius: 50px;
}

.field-small {
  @extend .field;
  max-width: calcRem(76px);
}

.field-xsmall {
  @extend .field;
  max-width: calcRem(56px);
  text-align: center;
}

.field-line {
  border: none;
  border-bottom: 1px solid;
  border-color: $lineGrey;
  max-width: 106px;

  &:focus {
    border-bottom: 1px solid;
    border-color: $lineGrey;
  }
}

//if on a dark bg
.field-dark-bg {
  border-color: $white;
}

//field messaging
.field-msg {
  font-size: calcRem(12px);
  margin-bottom: 0;

  .field + & {
    margin-top: calcRem(4px);
  }
}

//Custom checkbox

.custom-checkbox-outer {
  display: flex;
  align-items: center;
  line-height: 100%;

  // & + & {
  //   margin-top: calcRem(4px);
  // }

  &.label.block-label {
    margin-bottom: 0;
  }

  &.label.block-label + &.label.block-label {
    margin-left: calcRem(0px);
    margin-top: calcRem(16px);
  }
}

.custom-checkbox-inner {
  display: inline-block;
}

.custom-checkbox {
  background-color: $white;
  border-radius: $radius;
  border: 2px solid;
  border-color: $darkGrey;
  display: block;
  float: left;
  height: calcRem(18px);
  min-height: calcRem(18px);
  position: relative;
  vertical-align: top;
  width: calcRem(18px);
  min-width: calcRem(18px);

  :checked ~ & {
    background-color: $darkGrey;
  }
}

.custom-checkbox-icon {
  @include centerer;
  display: none;

  input[type="checkbox"]:checked ~ .custom-checkbox & {
    display: block;
  }

  svg {
    display: block;
    fill: $white;
  }
}

.custom-checkbox-label {
  display: block;
  font-size: 0.75rem;
  line-height: 1.25;
  margin-bottom: 0;
  padding-left: 0.5rem;

  // .custom-checkbox + & {
  //   margin-left: calcRem(8px);
  // }
}

// checkbox modifier

.already-submits-project{
  position: relative;
  &:after {
    display: block;
    content: '';
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -5px;
    left: -5px;
  }
}

//Custom radio
.custom-radio-outer {
  line-height: 100%;

  &.label + &.label {
    margin-left: calcRem(16px);
  }
  &.label.block-label + &.label.block-label {
    margin-left: calcRem(0px);
    margin-top: calcRem(16px);
  }
}

.custom-radio {
  background-color: $white;
  border-radius: calcRem(50px);
  border: 2px solid;
  border-color: $darkGrey;
  display: block;
  float: left;
  height: calcRem(18px);
  min-height: calcRem(18px);
  position: relative;
  vertical-align: top;
  width: calcRem(18px);
  min-width: calcRem(18px);
}

.custom-radio-icon {
  @include centerer;
  background-color: $darkGrey;
  border-radius: calcRem(50px);
  display: none;
  height: calcRem(10px);
  width: calcRem(10px);

  input[type="radio"]:checked ~ .custom-radio & {
    display: block;
  }
}

.custom-radio-label {
  display: block;
  overflow: hidden;
  font-size: calcRem(12px);
  line-height: 1.6;
  margin-bottom: 0;
  padding-left: 0.5rem;

  // .custom-radio + & {
  //   margin-left: calcRem(8px);
  // }
}

//Custom light switch/knob
.custom-switch-outer {
  line-height: 100%;

  & + & {
    margin-top: calcRem(4px);
  }
}

.custom-switch-label {
  margin-bottom: calcRem(8px);
}

.custom-switch {
  background-color: #8b8b8b;
  box-shadow: inset 2px 1px 2px rgba(0,0,0,.15);
  border-radius: 50px;
  height: calcRem(14px);
  width: calcRem(34px);
  position: relative;
  transition: background-color 125ms ease-in;

  :checked ~ & {
    background-color: $blue;
    transition: background-color 250ms ease-in;
  }
}

.custom-switch-knob {
  background-color: $darkGrey;
  border-radius: 100%;
  height: calcRem(20px);
  width: calcRem(20px);
  position: absolute;
  top: 50%;
  transform: translateX(0%) translateY(-50%);
  transition: transform 125ms ease-in;

  :checked ~ .custom-switch & {
    background-color: lighten($darkGrey, 10%);
    transform: translateX(100%) translateY(-50%);
    transition: transform 250ms ease-out;
  }
}

.custom-select-wrap {
  cursor: pointer;

  &.inline-field-wrap {
    min-width: calcRem(200px);
  }

  &.disabled {

    .custom-select-icon svg {
      fill: #888888;
    }
  }

  .field {
    padding-right: calcRem(40px);

    &[disabled] {
      color: #888888;
    }
  }
}

.custom-select-icon {
  @include centerer(false,true);
  pointer-events: none;
  right: calcRem(20px);
  z-index: 2;

  svg {
    display: block;
    fill: $darkGrey;
    transform: rotate(90deg);
  }
}

.search-label {
  @include centerer(false,true);
  height: calcRem(18px);
  width: calcRem(18px);
  left: calcRem(16px);

  svg {
    fill: $darkGrey;
  }
}

.search-field {
  padding-left: calcRem(40px);
}

.custom-file-outer {
  display: block;
}

.custom-file-label {
  display: block;
  font-size: calcRem(14px);
  list-style-type: none;
}

.custom-file-remove {
  align-items: center;
  display: flex;
  font-size: calcRem(12px);

  .custom-file-outer + & {
    margin-top: calcRem(8px);
  }
}

.custom-file-remove-icon {
  margin-right: calcRem(4px);

  svg {
    height: 14px;
  }
}

//~~~~ LAYOUT GOODIES
.field-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

$col2: 2;
$col3: 3;
$col4: 4;

.field-colcount-#{$col2} {
  .field-col-1-2 {
    width: calc(50% - (16px / #{$col2}));
  }

  .field-col-1-3 {
    width: calc(33.333333333% - (16px / #{$col2}));
  }

  .field-col-2-3 {
    width: calc(66.666666667% - (16px / #{$col2}));
  }
}

.field-colcount-#{$col3} {}

.field-colcount-#{$col4} {
  .field-col-1-4 {
    width: calc(25% - (16px / #{$col4}));
  }
}

.custom-date-range {
  display: flex;
  align-items: center;
  margin-bottom: calcRem(16px);
  .custom-date-range-field {
    &:last-child {
      right: 0;
    }
  }
}

.custom-date-range-field {
  position: relative;
  width: calc(50%);
}

.custom-date-range-icon {
  font-family: $font-text-bold;
  line-height: 0;
  text-align: center;
  width: calcRem(40px);

  svg {
    margin: auto;
  }
}

// Some sample styling for the <date-picker> tag; written in Stylus
date-picker {
  position: relative;
  display: block;
}

.picker {
  background-color: $white;
  position: absolute;
  width: 100%;
  z-index: 3;

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  table thead {
    border-left: 1px solid;
    border-right: 1px solid;
    border-top: 1px solid;
    border-color: $black;
    font-family: $font-text-bold;
  }

  table th {
    padding-bottom: calcRem(8px);
    padding-top: calcRem(8px);
  }

  table td {
    background-color: $white;
    border: 1px solid;
    border-color: $black;
    cursor: pointer;
    padding: calcRem(16px);
  }
  table td.cur {
    background-color: $lightGrey;
  }
  table td.selected {
    background-color: $black;
    color: $white;
    font-family: $font-text-bold;
  }

  button {
    font-family: $font-text-bold;
    padding: calcRem(8px);
  }

  /*.custom-date-range & {
    width: 200%;
  }*/
}

.monthSelector {
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: $black;
  padding: calcRem(16px);

  & > div {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.currMonth {
  font-family: $font-text-bold;
  font-size: calcRem(24px);
  min-width: calcRem(180px);
  text-align: center;
}

.currYear {
  min-width: calcRem(180px);
  text-align: center;
}

.tag-cloud {
  margin-top: calcRem(8px);
}

asset-form-control-display {
  display: block;
  margin-bottom: 0.5em;
}

.hours-picker {
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .hours-dropdown {
    width: 35%;
  }
}

asset-form-section + asset-form-section h3 {
  margin-top: 2rem;
}

.code-editor__container {
  height: 75vh;
  //border: 2px solid #d8d8d8;
}

.code-editor__container--small {
  height: 25vh;
}

.code-editor {
  position: relative; 
  width: 100%; 
  height: 100%;
  border: 2px solid #c8c8c8;
  line-height: 1.75;

  // Ace overrides
  .ace_gutter {
    z-index: 0;
    padding: 0.5rem 0;
  }

  .ace_scroller {
    padding: 0.5rem 0.25rem;
  }
  
  &.ace_focus {
    border: 2px solid #4fc3f7;
  }

}
