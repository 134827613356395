/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  # The Layout Machine!!!
  
  ## 4 parameters:
    + $count: number of columns
    + $numerator: top part of fraction, num to be divided from
    + $denominator: bottom of fraction, num to be divided by
    + $spacing: gutter size
  ## 1 condition:
    + if $count (column) > than 1
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

@mixin layout-machine
(
  $count: 1,
  $numerator: 1,
  $denominator: 1,
  $spacing: calcRem(16px)
){
    $ratio: percentage($numerator / $denominator);

    @if $count > 1 {
      float: left;
      margin-right: $spacing;
      width: calc(#{$ratio} - #{$spacing} + #{$spacing} / #{$count});

      // remove spacing from last specified :nth

      &:nth-of-type(#{$count}n) {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      // add spacing to all except for first $count group

      &:nth-child(n+#{$count+1}) {
        margin-top: $spacing;
      }
  }
  @else {
    width: #{$ratio};
  }
}

body {
  background-color: $lightGrey;
}

.row,
.grid {
  padding: calcRem(40px) calcRem(42px);

  & + .less-top-pad {
    padding-top: 0;
  }
}

.inner-row {

  & + & {
    margin-top: calcRem(40px);
  }
}

.col {

  & > :last-child {
    margin-bottom: 0;
  }
}

.colcount-2 {

  .col-1-2 {
    @include layout-machine(2,1,2,16px);
  }

  .col-1-4 {
    @include layout-machine(2,1,4,16px);
  }

  .col-2-4 {
    @include layout-machine(2,2,4,16px);
  }

  .col-3-4 {
    @include layout-machine(2,3,4,16px);
  }
}

.colcount-3 {

  .col-1-3 {
    @include layout-machine(3,1,3,16px);
  }

  .col-2-3 {
    @include layout-machine(3,2,3,16px);
  }
}

.colcount-4 {

  .col-1-4 {
    @include layout-machine(4,1,4,16px);
  }

  .col-2-4 {
    @include layout-machine(4,2,4,16px);
  }

  .col-3-4 {
    @include layout-machine(4,3,4,16px);
  }
}

.static-column {
  @include tablet-landscape-up {
    float: left;
    width: calcRem(300px);
  }

  & + & {
    margin-top: calcRem(32px);

    @include tablet-landscape-up {
      margin-top: 0;
      padding-left: calcRem(40px);
      width: calc(100% - 300px);
    }
  }
}















.side-menu {
  background: $darkGrey;
  color: $white;
  display: inline-block;
  height: 100vh;
  left: 0;
  padding: calcRem(8px);
  position: fixed;
  top: 0;
  width: calcRem(216px);

  overflow-x: hidden;
  overflow-y: auto;

  &::-moz-scrollbar { 
    display: none; 
  }
  &::-ms-scrollbar { 
    display: none; 
  }
  &::-o-scrollbar { 
    display: none; 
  }
  &::-webkit-scrollbar { 
    display: none; 
  }
  &::scrollbar { 
    display: none; 
  }
}

.brand-head {
  margin-bottom: calcRem(36px);
  padding-left: calcRem(20px);
  padding-right: calcRem(20px);
  padding-top: calcRem(16px);

  @include max-height(42.5rem) {
    margin-bottom: calcRem(10px);
  }
}

.brand-head-img,
.brand-head-text {
  display: inline-block;
  vertical-align: middle;
}

.brand-head-img {
  width: calcRem(48px);
}

.brand-head-text {
  font-family: $font-text-bold;
  font-size: calcRem(16px);
  margin-bottom: 0;
  text-transform: uppercase;
}

.nav-control {
  padding-bottom: calcRem(10px);
  padding-left: calcRem(20px);
  padding-right: calcRem(20px);
  padding-top: calcRem(10px);
}

.nav-control-title {
  color: $midGrey;
  margin-bottom: 0;
}

.nav {}

.nav-item {
  text-decoration: none;
  border-radius: $radius;
  color: $midGrey;
  display: block;
  padding: calcRem(10.5px) calcRem(20px);
  width: 100%;
  transition: color 0.3s ease;

  &:hover {
    color: $white;
  }

  &.active {
    background-color: $blue;
    color: $white;
    font-family: $font-text-bold;
  }
}

.main-content {
  display: block;
  left: calcRem(216px);
  position: relative;
  width: calc(100% - 216px);
}










/*
  WORKFLOW specific layout
*/

.workflow {
  display: inline-block;
  outline: 1px solid #000000;
  padding: 0.5rem;
  margin: 0.5rem;
}

.workflow-creation,
.current-workflow {
  display: block;
  margin: 1rem 0;
}

.workflow-node-wrap {
  display: inline-block;
  vertical-align: top;
}

.created-workflow {
  .created-workflow-name {
    width: 80%;
    line-height: 1.2;
    font-size: 1.5em;
    font-weight: bold;
    padding-left: 0.3em;
  }
}

.useraction-list {

  .useraction-item {
    background: #dfdfdf;

    &:nth-child(2n) {
      background: #bebebe;
    }
  }
}

.login-form {
  padding: calcRem(40px);
  margin: 10em auto;
  max-width: 90%;

  @include tablet-landscape-up {
    max-width: 50%;
  }

  h1 {
    margin-bottom: calcRem(32px);
  }

  .login-error {
    color: $red;
    display: block;
    font-size: calcRem(12px);
    margin-bottom: 0;
  }
}