.report-column-block {
  display: block;

  + .report-column-block {
    margin-top: 1.5rem;
  }
}

.report-column {

  custom-checkbox .checkbox-label {
    font-weight: bold;
  }
}

.report-filters-list {

}

.report-filter {
  background-color: $white;
  padding: 1rem;
  border: 1px solid #bbbbbb;

  + .report-filter {
    border-top: none;
  }

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
}