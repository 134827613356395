.table {
  display: table;
  font-size: calcRem(12px);
  margin-bottom: calcRem(16px);
  width: 100%;
  text-align: left;

  //un-table it in grid layout
  //hide it in the thead
  .grid-table & {
    display: block;
  }

  p {
    margin-bottom: 0;
  }
}

.barebones-table,
.grid-table {
  @extend .table;
}

.thead {
  color: #444444;
  display: table-header-group;
  font-family: $font-text-bold;
  text-transform: uppercase;

  //hide it in the thead
  .grid-table & {
    display: none;
  }
}

.th {
  display: table-cell;
  padding: calcRem(10px);
  vertical-align: middle;

  //un-table it in grid layout
  //hide it in the thead
  .grid-table & {
    display: block;
  }
}

.tbody {
  color: #777777;
  display: table-row-group;

  &--obligation {
    .td {
      border-bottom: none;
    }
  }
  &--term {
    .td {
      border-top: none;
      border-bottom: 1px solid $lineGrey;
      background: $lightGrey;
      font-size: 11px;
      height: auto;
      padding-top: calcRem(4px);
      padding-bottom: calcRem(4px);

      &:first-child {
        width: 40%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      input {
        font-size: 11px;
        max-width: calcRem(45px);
        padding: calcRem(4px);
        text-align: center;
      }
    }
  }
}

.tr {
  display: table-row;

  .tbody & {
    background-color: $white;
  }

  //un-table it in grid layout
  //hide it in the thead
  .grid-table & {
    @include layout-machine(6,1,6,16px);
    background-color: $white;
    border: 1px solid;
    border-color: $lineGrey;
    margin-bottom: calcRem(16px);
  }
}

.td {
  border-top: 1px solid;
  border-color: $lineGrey;
  display: table-cell;
  height: calcRem(50px);
  padding: calcRem(10px);
  vertical-align: middle;

  //all `td`s in a grid-table
  //un-table it in grid layout
  //hide it in the thead
  .grid-table & {
    display: block;
    padding: calcRem(14px) calcRem(16px);
  }
  
  //the last target all the `td` in the last `tr`
  .tr:last-of-type & {
    border-bottom: 1px solid;
    border-color: $lineGrey;
  }

  //the last target all the `td` in the last `tr`
  .grid-table .tr:last-of-type & {
    border: none;
  }

  //first type of `td`
  &:first-of-type {
    border-left: 1px solid;
    border-color: $lineGrey;
  }

  //all `td`s in a grid-table
  .grid-table &:first-of-type {
    border: none;
    height: calcRem(166px);
    overflow: hidden;
    padding: 0;
    position: relative;
    width: auto;
  }

  //first type of `td` inside `barebones-table`
  .barebones-table &:first-of-type {
    width: calcRem(20px);
  }

  //img inside first type of `td`
  &:first-of-type img {
    display: block;
    max-width: 100%;
  }

  //the last target all the `td` in the last `tr`
  .grid-table &:first-of-type img {
    @include centerer;
  }

  //last type of `td`
  &:last-of-type {
    border-right: 1px solid;
    border-color: $lineGrey;
  }

  //last type of `td` inside `barebones-table
  .barebones-table &:last-of-type {
    border-left: 1px solid;
    border-color: $lineGrey;
    position: relative;
    width: calcRem(40px);
  }

  //last type of `td` inside `grid-table`
  .grid-table &:last-of-type {
    border-right: none;
    text-align: left;
    width: auto;
  }
}

.td-title,
.td-info {
  color: $darkGrey;
  font-family: $font-text-bold;
}

[data-title="Type"] {
  .td-info {
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

[data-title="Image"] {
  width: calcRem(80px);

  img {
    display: block;
    max-width: 110%;
  }
}

[data-title="Icon"] {
  width: calcRem(18px);
}

[data-title="Name"] {
  color: $darkGrey;
  font-family: $font-text-bold;

  * {
    margin-bottom: 0;
  }
}

[data-title="Date Added"] {
  width: calcRem(170px);
}

[data-title="Actions"] {
  position: relative;
  text-align: center;
  width: calcRem(96px);

  button,
  a {
    display: inline-block;
  }

  button + button,
  a + a,
  button + a,
  a + button {
    margin-left: calcRem(10px);
  }
}


.faux-table {
  font-size: calcRem(12px);
  &--6-col {
    .faux-table-controls {
      margin-bottom: 0;
    }
  }
}

.faux-table-controls {
  font-size: calcRem(14px);
  margin-bottom: calcRem(16px);
  text-align: right;

  .form {
    border: 1px solid;
    border-color: $lineGrey;
    border-radius: $radius;
    padding: calcRem(16px);
    background-color: $white;
    text-align: left;
    margin-top: calcRem(16px);
    margin-bottom: 0;
  }

  .faux-table-controls-collapse-row {
    cursor: pointer;
  }
}

.faux-thead {
  font-family: $font-text-bold;
}

.faux-tr {
  display: block;
  font-size: 0px;
  position: relative;
  width: 100%;

  .faux-tbody & {
    background-color: $white;
  }
  .faux-tbody &:hover {
    background-color: $lightGrey;
  }
}

.faux-tbody {
  border-bottom: 1px solid $lineGrey;
  .faux-tr--expanding {
    cursor: pointer;
    max-height: 100px;
    transition: all 0.3s ease;
    overflow: hidden;
    .faux-td, p {
      cursor: pointer;
    }
    &.active {
      max-height: 0;
      & + .expanded-tr {
        max-height: 9999px;
        overflow: auto;
      }
    }
  }
  .expanded-tr {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    .faux-table-controls {
      margin-bottom: 0;
    }
    .form {
      border-bottom: none;
      margin-top: 0;
      .table {
        margin-bottom: 0;
      }
      .btn-group {
        margin-top: 0.5rem;
        width: 100%;
      }
    }
  }
}

.faux-th,
.faux-td {
  display: inline-block;
  font-size: calcRem(12px);
  padding: calcRem(14.5px);
  vertical-align: middle;
  width: percentage(1/5);

  &:nth-child(1) {
    width: 35%;
  }
  &:nth-child(2) {
    width: 20%;
  }
  &:nth-child(3) {
    width: 20%;
  }
  &:nth-child(4) {
    width: 12.5%;
  }
  &:nth-child(5) {
    width: 12.5%;
  }
  .faux-table--6-col & {
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 25%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 7.5%;
    }
    &:nth-child(6) {
      width: 7.5%;
    }
  }
}

.faux-th {
  padding-top: calcRem(6px);
}

.faux-td {
  border-top: 1px solid;
  border-color: $lineGrey;
  cursor: pointer;

  .faux-tr.active & {
    cursor: auto;
  }  

  &:first-of-type {
    border-left: 1px solid;
    border-color: $lineGrey;
  }
  &:last-of-type {
    border-right: 1px solid;
    border-color: $lineGrey;
  }

  .faux-tr:last-of-type & {
    border-bottom: 1px solid;
    border-color: $lineGrey;
  }

  :last-child {
    margin-bottom: 0;
  }

  .icon svg {
    max-height: calcRem(13px);
  }
}

.faux-table-btn {
  background-color: $white;
  border: 1px solid;
  border-color: $lineGrey;
  left: 0;
  padding: calcRem(4px);
  position: absolute;
}

.faux-tfoot {}

.faux-table-title,
.faux-table-info {}

.faux-table-info {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}

.faux-table-title {
  font-family: $font-text-bold;
}

.table-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.table-info-records {
  font-family: $font-text-bold;
}

.table-info-export a {
  font-size: 0.875rem;
  text-decoration: none;
  color: $midGrey;
  display: flex;
  align-items: center;
  transition: color 400ms;

  &:hover {
    color: $darkGrey;
  }

  svg {
    stroke: currentColor;
    margin-left: 0.5rem;
  }
}

.column-sort-button {
  display: flex;
  align-items: center;
  text-transform: uppercase;

  svg {
    margin-left: 0.5rem;
  }
}