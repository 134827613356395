.modal-bg {
  background-color: rgba($black, .85);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.modal-close {
  right: calcRem(20px);
  top: calcRem(20px);
  position: absolute;
  height: calcRem(18px);
  width: calcRem(18px);

  svg {
    height: inherit;
    width: inherit;
  }
}

.modal {
  @include centerer;
  background-color: $white;
  max-width: calcRem(640px);
  padding-bottom: calcRem(40px);
  padding-left: calcRem(40px);
  padding-right: calcRem(40px);
  padding-top: calcRem(40px);
  position: fixed;
  width: 90%;
  z-index: 3;

  .modal-info {
    display: block;
    text-align: center;
  }
  
  //prompt modal
  .file-card & {
    max-width: 560px;
    text-align: left;
  }
}

.static-modal {
  @extend .modal;
  position: 0rem;
  left: auto;
  top: auto;
  transform: none;
}

.modal-title {
  font-size: calcRem(54px);
  line-height: 0.953125;
}

.modal-body {
  .modal-header + &,
  .modal-footer + & {
    margin-top: calcRem(40px);
  }
}

.modal-footer {

  .modal-header + &,
  .modal-body + & {
    margin-top: calcRem(40px);
  }

  &.button-group {
    margin-bottom: calcRem(-8px);
  }
}