.recently__save__btn {
  position: relative;
}

.update_db_save_grp {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.expiry__date {
  position: relative;
  margin-left: auto;
  color: lightgray;
}

.date_helper_text {
  margin-top: 1rem;
}

.filter__sort {
  display: flex;
}

.filter__cover {
  position: absolute;
  width: 802px;
  height: 284px;
  z-index: 2;
}