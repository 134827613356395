.content-img {
  max-width: 100%;
}

.thumbnail-img {
  background-color: $white;
  border: 1px solid;
  border-color: $lineGrey;
  margin-bottom: calcRem(16px);
  max-width: 300px;

  svg,
  img {
    display: block;
    margin: auto;
    max-width: 100%;
    width: 100%;
  }
}