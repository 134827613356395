.grid-square {
  height: $gridSize;
  width: $gridSize;
  border-bottom: 1px solid $lineGrey;
  border-right: 1px solid $lineGrey;
}

.workflow-tile-layer {
  z-index: 10;
  .workflow-node {
    z-index: 10;
  }
}

.workflow-board-layer {
  display: block;
  border: 1px solid $lineGrey;
  margin: 0;
  width: calc(100% - 150px);
  // float: right;
  float: left;
  height: 80vh;
  overflow: scroll;
  position: relative;

  margin-left: 40px;
}

.workflow-board-tile {
  background: $white;
  display: inline-block;
  border-bottom: 1px solid $lineGrey;
  border-right: 1px solid $lineGrey;
  width: $gridSize;
  height: $gridSize;
  margin: 0;
  position: absolute;

  &.current-hover {
    background: $inactive;
  }
  &.potential {
    background: $white;
    box-shadow: inset 0px 0px 0px 7px $blue;
  }
}

.board-spacer {
  width: $gridSize * $gridNumX;
  height: $gridSize * $gridNumY;
  display: block;
}

@for $i from 0 through $gridNum - 1 {
  .workflow-board-tile.x--#{$i}, .workflow-node.x--#{$i} {
    left: ($i) * $gridSize;
  }
  .workflow-board-tile.y--#{$i}, .workflow-node.y--#{$i} {
    top: ($i) * $gridSize;
  }
}

.workflow-tile-select {
  display: block;
  float: left;
  text-align: center;
  width: 64px;

  .workflow-node {
    position: relative;
    .tile {
      margin-bottom: 0.5em;
    }
    .dragged-tile {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9999;
    }
    &.being-dragged {
      box-shadow: 1px 1px 2px $midGrey;
      .dragged-tile {
        display: block;
        pointer-events: none;
      }
    }
  }
}

.workflow-lines-layer {
  display: block;
  position: absolute;
  width: $gridSize * $gridNumX;
  height: $gridSize * $gridNumY;
  top: 0;
  left: 0;
  z-index: 9;

  .action-line {
    /*stroke: $blue;*/
    stroke-width: 2;
    position: absolute;
    width: $gridSize * $gridNumX;
    height: $gridSize * $gridNumY;
    z-index:0;

    &.btop {
      z-index: 2;
    }
  }
}