.form-designer {
    padding: 10px;
}

.form-designer .title {
    margin-left: 10px;
    font-size: 35px;
}

.form-designer .title-input {
    width: 350px;
    font-size: 30px;
}

.form-designer .form-designer-section {
    margin-top: 10px;
    padding-left: 10px;
    border-bottom: 5px solid #000000;
}

.form-designer .form-designer-control {
    
    span {
        max-width: 400px;
        word-wrap: normal;
        float: left;
    }

    label {
        display: block;
        float: left;
        width: 200px;
        font-weight: bold;
    }
    
    input {
        margin-right: 5px;
        margin-left: 5px;
        font-size: 15px;
        width: 200px;
    }

    select {
        margin-left: 5px;
        width: 200px;
    }

    margin: 10px;
    width: 750px;
}


.form-designer button {
    background: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    text-decoration: none;
    min-width: 150px;
    height: 25px;
    margin-right: 5px;
    margin-left: 5px;
    stroke: #000000;

    &:hover {
        background: #000000;
        color: #ffffff;
        cursor: pointer;
        stroke: #ffffff;
    }
}

.form-designer .section-title-block {

    input {
        width: 350px;
        font-size: 20px;
    }

    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #000000;
}

.form-designer .form-designer-toolbox {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border-top: 1px dashed #000000; 
    border-left: 1px dashed #000000;

    input {
        margin-right: 5px;
        margin-left: 5px;
        min-width: 250px;
        font-size: 16px;
    }

    label {
        display: block; 
        float: left; 
        width: 150px;
    }

    textarea {
        margin-right: 5px;
        margin-left: 5px;
        font-size: 16px;
        width: 350px;
        height: 60px;
        border: 1px solid #cccccc;
    }
}

.form-designer-multi-option-area {
    margin-top: 5px;
    margin-left: 5px;
    border: 1px solid #cccccc;
    width: 245px;
    height: 75px;
    max-height: 75px;
    overflow: auto;
    padding: 5px;
    font-size: 16px
}

.form-designer .narrow-button {
    min-width: 50px;
}

.form-designer .wide-button {
    min-width: 250px;
}

.form-designer .short-button {
    height: 20px;
}

.form-designer .tall-button {
    height: 35px;
}

.form-designer .header {
    min-height: 50px;
    border-bottom: 5px solid #000000;
    padding-bottom: 5px;

    h2 {
        margin: 0;
    }
}

.form-designer ul {
    padding-top: 10px;
    
    li {
        list-style-type: none;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #cccccc;
        padding-top: 10px;
        padding-bottom: 10px;
        max-width: 800px;
    }
}
