.portrait {}

.portrait__img {
  img {
    display: block;
    max-width: 100%;
  }
}

.portrait__controls {
  .portrait__img + & {
    margin-top: calcRem(16px);
  }
}

.portrait__list {
  list-style-type: none;
  margin-bottom: 0;
  text-align: center;

  li {
    vertical-align: bottom;
  }

  li + li {
    margin-left: calcRem(8px);
  }

  svg {
    display: block;
    fill: $darkGrey;
    height: 16px;
  }
}