.pagination {
  font-family: $font-text-bold;
  text-align: center;

  //if preceeds another
  margin-top: calcRem(32px);

  svg {
    pointer-events: none;
  }
}

.pagination-list {}

.pagination-page {
  min-width: calcRem(26px);
  min-height: calcRem(26px);
  text-align: center;
  padding: 0 calcRem(4px);
  line-height: calcRem(26px);

  &.active {
    background-color: #000;
    border-radius: 20px;

    a, button {
      color: #fff;
    }
  }
}

.pagination-start {
  @extend .pagination-page;
}

.pagination-prev {
  @extend .pagination-page;
}

.pagination-next {
  @extend .pagination-page;
}

.pagination-end {
  @extend .pagination-page;
}