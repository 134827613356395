.filters {
  overflow: hidden;
}

.filter__group {
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: calcRem(30px);
  }
}

.filter {
  @include layout-machine(2,1,2,16px);
  // width: calc(50% - 8px);
}

.filter--full-width {
  width: 100%;
}

.simple-filter {
  @extend .filter;
}

.filter__list {
  background-color: #e8e8e8;
  border: 2px solid;
  border-color: $lineGrey;
  border-radius: $radius;
  height: calcRem(228px);
  list-style-type: none;
  margin-bottom: 0;
  overflow: auto;
  padding-left: 0;
}

.filter__item {
  background-color: $white;
  color: $darkGrey;
  cursor: pointer;
  padding: calcRem(10.5px) calcRem(16px);
  position: relative;
  display: flex;
  align-items: center;

  &.recent__item {
    justify-content: space-between;
  }

  .simple-filter & {
    padding: calcRem(6px) calcRem(16px);
  }

  & + & {
    border-top: 1px solid;
    border-color: $lineGrey;
  }
  &:last-child {
    border-bottom: 1px solid;
    border-color: $lineGrey;
    box-shadow: 0px 1px 2px rgba(0,0,0,.09);
  }

  &.is-active {
    background-color: $darkGrey;
    color: $lightGrey;
  }

  &.required-category {
    &:after {
      content: '*';
      margin-left: calcRem(4px);
      display: inline-block;
    }
  }

  &--small {
    font-size: 0.75rem;
    
    .simple-filter & {
      padding: 0.5rem 1rem;
    }
    
    .filter__icon svg {
      width: 6px;
      height: auto;
    }
  }
}

.filter__grab {
  cursor: grab;
  margin-right: calcRem(16px);
  width: calcRem(22px);

  span {
    height: 1px;
    width: 100%;
    background-color: $midGrey;
    display: block;
  }
  span ~ span {
    margin-top: 4px;
  }
}

.order-sort {
  cursor: pointer;
  height: 1rem;
  margin-right: calcRem(16px);
  position: relative;
  text-align: left;
  top: -14px;
  width: calcRem(15px);

  .sort {
    display: block;
    height: auto;
    position: relative;
    &__up {
      transform: rotate(-90deg);
      left: 1px;
    }
    &__down {
      transform: rotate(90deg);
    }
  }

  .filter__item.is-active & {
    path {
      fill: $blue;
    }
  }
}

.filter__icon {
  @include centerer(false,true);
  opacity: 0;
  right: calcRem(16px);
  transition: opacity 250ms ease;

  .filter__item:hover &,
  .filter__item.is-active & {
    opacity: 1;
  }

  svg {
    fill: $midGrey;
  }
  .is-active & svg {
    fill: $blue;
  }
}

.filter__controls {
  margin-bottom: calcRem(16px);
  margin-left: auto;
  width: 40%;

  &.recent__item {
    width: unset;
  }

  .filter__list + & {
    margin-bottom: 0;
    margin-right: auto;
    margin-top: calcRem(16px);
    width: auto;
  }
}