.box {
  border: 2px solid;
  border-color: $lineGrey;
  border-radius: $radius;
}

.dark-box {
  @extend .box;
  background-color: $darkGrey;
  border: none;
  color: $white;
}
