/* Custom Checkbox */
/* Styles for the custom-checkbox component */

custom-checkbox {

  label {

    .checkbox-icon {
      background-color: $white;
      border-radius: $radius;
      border: 2px solid $darkGrey;
      height: calcRem(18px);
      min-height: calcRem(18px);
      width: calcRem(18px);
      min-width: calcRem(18px);
    }
  }

  input[type=checkbox]:checked {

    + label {

      .checkbox-icon {
        background-color: $darkGrey;

        i {

          svg {
            fill: $white;
          }
        }
      }
    }
  }
}
