//an awesome clearfix
.group:after {
  content: "";
  display: table;
  clear: both;
}

//hide visually
//but not from screen readers
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.yeap {
  color: $green;
}

.nope {
  color: $red;
}

.right-align {
  text-align: right;
}

.to-camel-case {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}

// for IE select dropdowns
select::-ms-expand {
  display: none;
}

.space-below {
  margin-bottom: 1rem;
}

.space-above {
  margin-top: 1rem;
}