/* 
  Inner workings

  &__body
  &__section
  &__group
  &__innergroup
  &__child
  
*/

.drawer {
  background: $darkGrey;
  box-sizing: border-box;
  color: $white;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: calcRem(16px);
  position: fixed;
  right: -448px;
  top: 0;
  transition: all 0.3s ease;
  width: calcRem(420px);
  z-index: 100;

  &.active {
    right: 0;
  }
}

.drawer__close {
  position: absolute;
  right: calcRem(-1px);
  top: calcRem(-1px);
}

.drawer__header {
  background-color: darken($darkGrey, 16%);
  border: 2px solid;
  border-color: $midGrey;
  border-radius: $radius;
  padding: calcRem(16px);
}

.drawer__body {
  //when next to a header
  .drawer__header + & {
    margin-top: calcRem(16px);
  }
}

.drawer__section,
.drawer__group,
.drawer__innergroup,
.drawer__child,
.drawer__innerchild {
  position: relative;

  & + & {
    margin-top: calcRem(16px);
  }
}

.drawer__group {
  background-color: lighten($darkGrey, 5%);
  border: 2px solid;
  border-color: $midGrey;
  border-radius: $radius;
  margin-bottom: calcRem(16px);
  padding: calcRem(16px) calcRem(16px) calcRem(16px) calcRem(16px);
  position: relative;
  transition: background-color 100ms ease-in;

  &:hover {
    background-color: darken($darkGrey, 16%);
    transition: background-color 250ms ease-out;
  }
  
  &:last-of-type {
    margin-bottom: 0;
  }
}

.drawer__innergroup {
  padding-right: calcRem(30px);
}

.drawer__child {}
.drawer__innerchild {}

.drawer__add {
  .drawer__group + & {
    margin-top: calcRem(16px);
  }
}

.drawer__delete {
  position: absolute;
  height: calcRem(16px);
  width: calcRem(16px);

  .icon svg {
    height: calcRem(10px);
    width: calcRem(10px);
  }
}

.drawer__delete-rule {
  right: -10px;
  top: -10px;
}

.drawer__delete-condition {
  bottom: 0;
  right: 0;
}

.drawer__info {
  font-family: $font-text-bold;
  margin-bottom: calcRem(8px);
}

.drawer__msg {
  color: #f1f1f1;
  font-family: $font-text-bold;
  margin-bottom: calcRem(6px);
  margin-top: calcRem(6px);
  text-align: center;
  position: relative;

  span {
    position: relative;
    z-index: 2;
  }
  
  &:before {
    @include centerer;
    background-color: lighten($darkGrey, 5%);
    content: '';
    height: calcRem(30px);
    transition: background-color 100ms ease-in;
    width: calcRem(30px);
    z-index: 1;
  }

  .drawer__group:hover &:before{
    background-color: darken($darkGrey, 16%);
    transition: background-color 250ms ease-out;
  }

  &:after {
    @include centerer;
    border-top: 1px dotted;
    border-color: #999;
    content: '';
    height: 2px;
    width: 100%;
    z-index: 0;
  }
}