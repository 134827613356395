//this module lives at the start of every column
//it is meant to introduce the content

.intro {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: calcRem(30px);


  // border-left: 8px solid;
  // border-color: $blue;
  // margin-bottom: calcRem(20px);
  // padding-bottom: calcRem(30px);
  // padding-left: calcRem(16px);
  // padding-right: calcRem(16px);
  // padding-top: calcRem(30px);

  // :last-child {
  //   margin-bottom: 0;
  // }
}

.intro__body {
  
}

.intro__title {
  margin-bottom: 0;

  &:only-child {
    width: 100%;
  }
}

.intro__controls {
  
}