.trees {
  // border: 2px solid;
  // border-color: $lineGrey;
  // border-radius: $radius;
  font-size: calcRem(12px);

  ul {
    list-style-type: none;
    margin-bottom: 0;

    ul {
      padding-left: calcRem(20px);
    }
  }
}

.tree-header {
  font-family: $font-text-bold;
  padding-bottom: calcRem(5px);
  padding-left: calcRem(20px);
  padding-top: calcRem(5px);
}

.tree-info {
  margin-bottom: 0;
}

.tree {
  background-color: $white;
  font-family: $font-text-bold;
  padding: calcRem(20px);
  border: 2px solid;
  border-color: $lineGrey;
  border-radius: $radius;

  & & {
    border: none;
    border-left: 1px dotted;
    border-left-color: $midGrey;
    font-family: $font-text-reg;
    margin-bottom: calcRem(8px);
    margin-top: calcRem(8px);
    padding: 0;
    padding-left: calcRem(26px);
  }

  li + li {
    margin-bottom: calcRem(8px);
    margin-top: calcRem(8px);
  }
}

.tree-section {
  width: calc(50% - 4px);
  display: inline-block;

  & + & {
    padding-left: calcRem(16px);
  }
}

.tree-icon {
  display: inline-block;
  margin-right: calcRem(8px);
  // transform: translateX(-50%);
  vertical-align: bottom;
}