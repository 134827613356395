.tags {
  color: #444444;
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0;

  .filters + & {
    margin-top: calcRem(16px);
  }
}

.tag {
  background-color: $lineGrey;
  border: 1px solid;
  border-color: $lineGrey;
  border-radius: 6px;
  font-size: calcRem(12px);
  padding: calcRem(2px) calcRem(6px) calcRem(3px);

  .tags & {
    margin-bottom: calcRem(4px);
  }
}

.tag-light {
  @extend .tag;
  background-color: $white;
  border-color: $lineGrey;
}

.tag-dark {
  @extend .tag;
  background-color: $darkGrey;
  border-color: $darkGrey;
  color: $white;
}

.tag-fancy {
  @extend .tag;
  background-color: $blue;
  border-color: $blue;
  color: $white;
}

.tag-truncate {
  @extend .tag;
  cursor: pointer;
  
  svg {
    fill: #777777;
  }
}

.tag-icon {
  @extend .tag;
  border-radius: 50px;
  padding: calcRem(6px) calcRem(26px) calcRem(6px) calcRem(10px);
  position: relative;

  svg {
    @include centerer(false,true);
    display: block;
    fill: $midGrey;
    height: calcRem(8px);
    right: calcRem(10px);
    width: calcRem(8px);
  }
  &:hover svg {
    fill: $darkGrey;
  }
  + .tag-icon {
    margin-left: calcRem(4px);
  }

  &--small {
    font-size: 0.675rem;

    svg {
      width: 6px;
      height: auto;
      margin: 0 0.125rem;
    }
  }
}
