.workflow-node {
  display: inline-block;
  position: absolute;

  &.prototype {
    position: relative;
  }

  .tile {
    background: $darkGrey;
    cursor: pointer;
    display: inline-block;
    height: $gridSize;
    position: relative;
    width: $gridSize;
    z-index: 100;
    user-select: none; 

    &:after {
      @include centerer;
      color: $white;
      content: '';
      font-family: $font-disp-bold;
      font-size: 24px;
      // left: 13px;
      line-height: 1;
      // position: absolute;
      // top: 16px;
    }
    &--alt-color {
      background: $white;
      border: 1px solid $lineGrey;
      margin: 0 auto;
      &:after {
        color: $darkGrey;
      }
    }
  }

  &--start, &--end {
    .tile {
      &:after {
        font-size: 1.1em;
        font-weight: normal;
      }
    }
  }

  &--start {
    .tile {
      background: $green;
      &:after {
        color: $white;
        content: 'Start';
        font-family: $font-text-reg;
        font-size: calcRem(16px);
        // left: 0.7em;
        // top: 1.2em;
      }
    }
    .action-edge {
      display: none;
    }
  }

  &--end {
    .tile {
      &:after {
        color: $red;
        content: 'End';
        font-family: $font-text-reg;
        font-size: calcRem(16px);
        // left: 0.9em;
        // top: 1.2em;
      }
    }
  }

  &--action {
    .tile {
      &:after {
        content: 'Ac';
      }
    }
  }

  &--rule {
    .tile {
      &:after {
        content: 'Ru';
      }
    }
  }

  &--form {
    .tile {
      &:after {
        content: 'Fo';
      }
    }
  }

  &--template {
    .tile {
      &:after {
        content: 'Te';
      }
    }
  }

  &--template_rule {
    .tile {
      &:after {
        content: 'Tr';
      }
    }
  }

  .action-start, .action-edge {
    position: absolute;
  }

  .action-start {
    background: $blue;
    border-radius: 50%;
    top: $gridSize/2 - $edgeSize/2;
    left: $gridSize - $edgeSize/2;
    width: $edgeSize;
    height: $edgeSize;

    &--down {
      top: $gridSize - $edgeSize/2;
      left: $gridSize/2 - $edgeSize/2;
    }
    &--left {
      left: -$edgeSize/2;
    }
  }

  @for $i from 2 through $maxEdgesPerSide {
    .action-starts .action-start--right-#{$i},
    .action-starts .action-start--left-#{$i}, {
      @for $j from 1 through $i {
        &.action-start--right-#{$i}-#{$j},
        &.action-start--left-#{$i}-#{$j} {
          top: $gridSize/$i * $j - $gridSize/$i/1.5;
        }
      }
    }
    .action-starts .action-start--down-#{$i} {
      @for $j from 1 through $i {
        &.action-start--down-#{$i}-#{$j} {
          left: $gridSize/$i * $j - $gridSize/$i/1.5;
        }
      }
    }
  }




  .action-edge {
    border-left: $edgeSize solid transparent;
    border-right: $edgeSize solid transparent;
    
    border-top: $edgeSize solid $blue;

    margin-top: 0;

    top: -$edgeSize/2;
    left: $gridSize/2 - $edgeSize;
    width: 0;
    height: 0;

    z-index: 110;
  }
}