.designer {
  // border-top: 4px solid;
  // border-top-color: $blue;
  margin-bottom: calcRem(16px);

  max-width: calcRem(570px);
  width: 70%;;

  &.prototype {
    float: left;
    margin-right: calcRem(16px);
    max-width: calcRem(560px);
    width: 50%;
  }
  &.prototype:last-of-type {
    margin-right: 0;
  }
}

.designer__main {
  background-color: $white;
  border: 1px solid;
  border-top: 4px solid;
  border-color: $lineGrey;
  border-top-color: $blue;
  position: relative;
  
  & + & {
    margin-top: calcRem(40px);
  }
}

.designer__header {}

.designer__header,
.designer__body {
  // background-color: $white;

  :last-child {
    margin-bottom: 0;
  }
}

.designer__body {
  // border-left: 1px solid;
  // border-right: 1px solid;
  // border-color: $lineGrey;
}

.designer__footer {
  .designer__body + & {
    margin-top: calcRem(16px);
  }
}

.designer__area {
  // border: 1px solid;
  // border-color: $lineGrey;

  &:first-of-type,
  & + & {
    border-top: none;
  }
}

.designer__group {
  // border: 1px solid;
  // border-right: 1px solid;
  // border-color: $lineGrey;

  background-color: $white;
  padding: calcRem(16px) calcRem(20px);
  position: relative;

  //when groups are next to eachother
  // & + & {
  //   margin-top: calcRem(16px);
  // }

  &.is-edit-mode {
    box-shadow: 0 0 10px rgba(0,0,0,.35);
  }

  &:first-of-type {
    padding-top: calcRem(20px);
  }
  &:last-of-type {
    padding-bottom: calcRem(20px);
  }

  [data-mode="edit"] & {
    padding: 0;
  }

  [data-mode="display"] & {}
}

.designer__smallgroup {
  padding: calcRem(8px) calcRem(20px);
}

.designer__form {
  margin-bottom: 0;

  [data-mode="edit"] & {
    padding: calcRem(16px) calcRem(20px) calcRem(20px);
  }
}

.designer__title,
.designer__subtitle {
  color: $darkGrey;
  font-family: $font-disp-bold;
}

.designer__title {
  font-size: calcRem(32px);
  line-height: 1;
  margin-bottom: calcRem(16px);

  //override the form styles
  border: none;
  width: 100%;
  display: block;
}

.designer__subtitle {
  display: block;
  font-size: calcRem(18px);
  line-height: 1;
  margin-bottom: calcRem(16px);
  width: 100%;

  [data-mode="edit"] .designer__body & {
    border: none;
    height: calcRem(24px);
    margin-bottom: 0;
  }
}

.designer__desc {
  color: #8b8b8b;
  font-size: calcRem(14px);
  margin-bottom: calcRem(16px);

  //override the form styles
  border: none;
  display: block;
  width: 100%;

  .designer__header & {
    margin-top: 0;
  }

  .custom-radio-label &,
  .custom-checkbox-label & {
    font-size: calcRem(11px);
    font-family: $font-text-reg;
  }

  // .designer__title + &,
  // .designer__subtitle + & {
  //   margin-top: calcRem(-16px);
  // }
  
  //edit mode
  // [data-mode="edit"] .designer__header & {
  //   min-height: calcRem(60px);
  // }
  // //edit mode
  // [data-mode="edit"] .designer__body & {
  //   height: calcRem(26px);
  // }
}

.designer__info {
  display: block;
  font-size: calcRem(14px);
  margin-bottom: calcRem(16px);
  display: block;

  [data-mode="edit"] & {
    border: none;
    margin-bottom: 0;
    width: 100%;
  }
}

.designer__btn {
  background-color: $white;
  border: 1px solid;
  border-color: $lineGrey;
  font-family: $font-text-bold;
  font-size: calcRem(14px);
  // margin-bottom: calcRem(12px);
  padding: calcRem(16px);
  padding-left: calcRem(44px);
  position: relative;

  .designer__footer & {
    background-color: $darkGrey;
    color: $white;
  }

  //when a btn is next to a btn
  & + & {
    margin-left: calcRem(6px);
  }

  &:hover {
    border-color: $blue;
  }

  .icon {
    @include centerer(false,true);
    left: calcRem(16px);
    height: calcRem(16px);
    width: calcRem(16px);
  }
  .icon svg {
    fill: $blue;
    display: block;
    height: inherit;
    width: inherit;
  }
  
}

.designer__field {
  background-color: $lightGrey;
  border: 1px solid;
  border-color: $lineGrey;
  border-radius: $radius;
  color: $darkGrey;
  width: 100%;

  padding-bottom: calcRem(8px);
  padding-top: calcRem(8px);
  padding-left: calcRem(16px);
  padding-right: calcRem(16px);

  &:focus,
  &:active {
    border: 1px solid;
    border-color: $blue;
  }

  .icon svg {
    fill: $blue;
  }
}

.designer__opts {
  bottom: 0;
  position: absolute;
  text-align: right;
  transform: translateY(calc(100% + 17px));
  width: 100%;
}









.designerMenu {
  background-color: $white;
  border: 1px solid;
  border-top: 4px solid;
  border-color: $lineGrey;
  font-size: calcRem(12px);
  max-width: calcRem(260px);
  right: 0;
  position: absolute;
  // width: 30%;
  top: calcRem(-4px);
  transform: translateX(calc(100% + 20px));

  .form > div + div {
    margin-top: calcRem(8px);
  }
}

.designerMenu-field {
  margin-bottom: calcRem(4px);
}

.designerMenu-section {
  padding: calcRem(16px);

  &:hover {
    background-color: $ultraGrey;
  }

  & + & {
    border-top: 1px solid;
    border-color: $lineGrey;
  }
}

.designerMenu-info {
  margin-bottom: 0;
}







.designerRemote {
  align-items: center;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: $lineGrey;
  display: flex;
}







.designerUtility {
  height: calcRem(18px);
  position: absolute;
  right: calcRem(20px);
  top: 50%;
  text-align: center;
  transform: translateY(-50%) scale(0);
  transition: transform 125ms ease-in;
  width: calcRem(15px);

  .designer__main:hover & {
    transform: translateY(-50%) scale(1);
    transition: transform 250ms cubic-bezier(1, 0.76, 0.88, 2.06);
  }

  svg {
    fill: $blue;
    display: block;
    height: inherit;
    width: inherit;
  }
  &:focus svg,
  &:hover svg {
    fill: $darkGrey;
  }
}








.designerNav {
  position: relative;
  z-index: 1;
  width: calcRem(200px);
}

.designerNav-title {
  font-size: calcRem(14px);
  margin-bottom: 0;
  padding: calcRem(10px) calcRem(16px);
}

.designerNav-list {
  background-color: $white;
  border-radius: $radius;
  font-family: $font-text-bold;
  font-size: calcRem(13px);
  width: 100%;

  .nav-is-active & {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
    left: 0;
    position: absolute;
    top: 0;
  }
}

.designerNav-item {
  position: relative;
  display: none;

  .nav-is-active & {
    display: block;
  }

  &.item-active {
    display: block;
  }
}

.designerNav-btn {
  display: block;
  padding: calcRem(10px) calcRem(16px);
  padding-left: calcRem(44px);
  text-align: left;
  width: 100%;

  .item-active & {
    background-color: $darkGrey;
    font-family: $font-text-reg;
    color: $blue;
  }

  .item-active & svg {
    fill: $blue;
  }

  .nav-is-active .item-active & {
    background-color: $darkGrey;
  }

  .nav-is-active &:hover {
    background-color: $lightGrey;
  }

  .nav-is-active .item-active &:hover {
    background-color: $darkGrey;
  }
}

.designerNav-icon {
  height: 20px;
  left: calcRem(16px);
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    height: calcRem(16px);
  }

  &:last-of-type {
    left: auto;
    right: calcRem(16px);
    transform: translateY(-50%) rotate(90deg);
    height: 8px;
    width: 8px;
  }

  svg {
    display: block;
    height: inherit;
    width: inherit;
  }

  .designerNav-btn:hover & svg {
    fill: $blue;
  }
}






.designerData {
  align-items: center;
  border-top: 1px solid;
  border-top-color: $lineGrey;
  /*display: flex;*/
  /*justify-content: space-between;*/
  padding: calcRem(16px) calcRem(20px);

  .custom-checkbox-outer {
    text-align: center;
    width: 20%;
  }
}

.designerData-field-wrap {
  display: block;
  position: relative;
  /*width: calc(80% - 16px);*/
  width: 100%;
}

.designerData-checks {
  margin-top: 1em;
  width: 100%;
  .custom-checkbox-outer {
    float: left;
    width: 50%;
  }
}








.designerSettings {
  border-top: 1px solid;
  border-color: $lineGrey;
  padding: calcRem(16px) calcRem(20px);
  
  & > div {
    position: relative;
  }

  & > div + div {
    margin-top: calcRem(16px);
  }

  .custom-switch-outer {
    display: flex;
    align-items: center;
  }

  .custom-switch-label {
    font-family: $font-text-reg;
    margin-bottom: 0;
    width: calcRem(200px);
  }
  .custom-switch-little-label {
    @extend .custom-switch-label;
    width: calcRem(120px);
  }

  .designerNav-icon svg {
    fill: $blue;
  }
}






.designerController {
  background-color: $ultraGrey;
  font-size: calcRem(12px);
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: $lineGrey;

  & + & {
    border-top: none;
  }
}

.designerController-action {
  align-items: center;
  display: flex;
  padding: calcRem(10px) calcRem(20px);

  & + & {
    border-left: 1px solid;
    border-color: $lineGrey;
  }

  svg {
    display: block;
    fill: $blue;
    max-height: calcRem(14px);
  }
}

.designerController-prompts {
  justify-content: space-between;
  width: calcRem(400px);

  &:only-child {
    width: 100%;
  }

  & .custom-switch-outer {
    display: flex;
    align-items: center;
  }

  & .custom-switch-outer .label {
    font-family: $font-text-reg;
    margin-bottom: 0;
    margin-right: calcRem(10px);
  }
}

.designerController-commands {
  justify-content: space-between;
  width: calcRem(120px);
}

.designerController-options {
  justify-content: center;
  width: calcRem(50px);
}

.designerController-info {
  align-items: center;
  display: flex;
  margin-bottom: 0;
  margin-right: calcRem(4px);
  align-items: flex-start;

  .designerController-action:only-child & {
    margin-right: 0;
    width: 100%;
  }
}

.designerController-inputIcon {
  font-style: normal;
  margin-right: calcRem(4px);
  margin-top: calcRem(2px);
}

.designerController-inputWrap {
  display: block;
  width: 100%;
}

.designerController-link {
  color: $blue;
  cursor: pointer;
  display: inline-block;
  margin-left: calcRem(4px);
  margin-right: calcRem(4px);

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
}

.designerController-int {
  color: $darkGrey;
  font-family: $font-text-bold;
  display: block;
  margin-right: calcRem(4px);
  width: calcRem(16px);
}

.designerController-input {
  background-color: transparent;
  border: none;
  box-shadow: none;
  box-shadow: none;
  color: #8b8b8b;
  font-size: calcRem(12px);
  outline: none;
  padding: 0;

  .designerController-info:only-child & {
    width: 100%;
  }

  .designerController-info & + & {
    font-size: 11px;
  }
}

.designerController-switch {
  &:only-child {
    justify-content: flex-end;
  }
}






.designerFooter {
  background-color: $lineGrey;
  border-top: 1px solid;
  border-color: $lineGrey;
  padding: calcRem(10px) calcRem(20px);
}